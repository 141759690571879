// this is our just created themes.scss file, make sure the path to the file is correct
@use 'themes' as *;

// framework component styles
@use '@nebular/theme/styles/globals' as *;

// install the framework styles
@include nb-install() {
  @include nb-theme-global();
}

/* You can add global styles to this file, and also import other style files */
@import "../include.scss";

$body-colors: (
    //"background-body": #F5F5F5,
    "background-body": #f6fbff,
);

html {
    width: 100%;
    height: 100vh;

    body {
        margin: 0;
        background: map-get($body-colors,'background-body');
        font-family: 'Poppins', sans-serif;

        .padding {
            padding: 0 rem(30);
            box-sizing: border-box;
        }
    }
}
