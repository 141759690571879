@import "./functions.scss";

$breakpoint: 1000px;
$breakpoint-mobile: 1000px;
$max-width: 1400px;

/* Light mode */
:root {
  --background-color: #fafafa;
  --background-primary-btn: rgb(0,51,153);
  --background-primary-hover-btn: rgb(1,49,180);
  --outline-primary-text: rgb(0,51,153);
  --background-outline-primary-btn: rgba(51, 102, 255, .16);
  --paragraph-component-dark-color:#000730;

  /* INPUTS */
  --input-basic-background-darkmode: #FFFFFF;
  --input-basic-border-darkmode: #E4E9F2;
  --input-basic-hover-border-color: #e8e8ea;
  --input-basic-text-darkmode: #000;

  /* SELECTS */
  --select-basic-background-darkmode: #F6F9FC;
  --select-basic-border-darkmode: #E4E9F2;
  --select-basic-hover-border-color: #e8e8ea;
  --select-basic-text-darkmode: #000;

  /* SIDEBAR */
  --sidebar-component-background-color: rgb(0,51,153);

  /* BREADCRUMB */
  --breadcrumb-component-background-color: #fff;
  --breadcrumb-component-border-color: transparent;
  --breadcrumb-component-active-text-color: #000;

  ///* TABLE HEADER */
  --table-header-background-color: #fff;
  --table-header-text-color: #000;
  --table-header-headers-background: #F1F4F9;
  --table-header-headers-color: #ABACB4;
  --table-header-footer: rgb(0,51,153);
  --table-header-separator: #F4F4F4;
}

/* Dark mode */
[data-theme='dark'] {
  //--background-color: #333;
  //--background-color: #102A43;
  --background-color: #1A1A1A;
  --background-primary-btn: #81bfe6;
  --background-outline-primary-btn: rgba(129, 191, 230, 0.38);
  --background-primary-hover-btn: #55aae0;
  --outline-primary-text: #fff;
  --paragraph-component-dark-color:#fff;

  /* INPUTS */
  --input-basic-background-darkmode: #222222;
  --input-basic-border-darkmode: #2B2B2B;
  --input-basic-hover-border-color: #fff;
  --input-basic-text-darkmode: #fff;

  /* SELECTS */
  --select-basic-background-darkmode: #222222;
  --select-basic-border-darkmode: #2B2B2B;
  --select-basic-hover-border-color: #fff;
  --select-basic-text-darkmode: #fff;

  /* SIDEBAR */
  --sidebar-component-background-color: #2B2B2B;

  /* BREADCRUMB */
  --breadcrumb-component-background-color: #222222;
  --breadcrumb-component-border-color: #2B2B2B;
  --breadcrumb-component-active-text-color: #fff;

  ///* TABLE HEADER */
  --table-header-background-color: #222222;
  --table-header-text-color: #fff;
  --table-header-headers-background: #81bfe6;
  --table-header-headers-color: #fff;
  --table-header-footer: #81bfe6;
  --table-header-separator: #2b2b2b;
}
